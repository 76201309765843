import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateT'
})
export class DateTPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        return moment(value).format('DD MMM hh:mm');
    }

}
