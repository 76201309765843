import {Component, OnInit} from '@angular/core';
import {TicketsService} from '../../services/tickets.service';
import {TripsService} from '../../services/trips.service';
import * as moment from 'moment-timezone';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-trip-change',
    templateUrl: './trip-change.component.html',
    styleUrls: ['./trip-change.component.styl']
})
export class TripChangeComponent implements OnInit {
    ferrySchedule;
    selectedDay;
    loading = true;

    tripChangeForm: FormGroup;
    selectedDateControl = new FormControl(0);
    data;
    error;

    constructor(private ticketService: TicketsService,
                private tripService: TripsService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private sb: MatSnackBar) {
    }

    ngOnInit() {
        this.activatedRoute.paramMap.subscribe(paramsMap => {
            this.ticketService.getTicket(paramsMap.get('id')).subscribe(res => {
                this.data = res;
                this.loading = false;
            }, () => {
                this.router.navigate(['/']);
            });
        });
        this.tripChangeForm = new FormGroup({
            verification_code: new FormControl(null, Validators.required),
            tripId: new FormControl(null, Validators.required)
        });
        this.getTrips();
    }

    sendVerification() {
        this.ticketService.sendTripChangeVerification(this.data._id).subscribe(res => {
            this.sb.open('Verification sms sent to phone', null, {duration: 4000});
        });
    }

    selectTrip(trip) {
        this.tripChangeForm.patchValue({
            tripId: trip._id
        });
    }

    changeSelected(schedule) {
        this.selectedDay = schedule;
    }

    compareWith(c1, c2): boolean {
        return c1 && c2 ? c1._id === c2 : c1 === c2;
    }

    getTrips() {
        this.tripService.by_days().subscribe(res => {
            const sameValued = res.filter(item => item.bookable && this.data.price === item.price && item.ticketCounts.confirmed < item.capacity);
            console.log(sameValued.length, res.length);
            sameValued.sort(function (a, b) {
                if (a.departure > b.departure) {
                    return 1;
                }
                if (a.departure < b.departure) {
                    return -1;
                }
                return 0;
            });
            const test = sameValued.map((trip) => {
                return {
                    ...trip,
                    date: moment.tz(trip.departure, moment.tz.guess()).format('DD MMM')
                };
            });
            const something = {};
            for (const item of test) {
                if (something[item.date]) {
                    something[item.date].push(item);
                } else {
                    something[item.date] = [item];
                }
            }
            for (const key of Object.keys(something)) {
                something[key] = something[key].sort(function (a, b) {
                    if (a.from > b.from) {
                        return 1;
                    }
                    if (a.from < b.from) {
                        return -1;
                    }
                    return 0;
                });
            }
            this.ferrySchedule = Object.entries(something);
            this.selectedDay = 0;
        });
    }

    bookTicket() {
        this.ticketService.tripChange({
            _id: this.data._id,
            ...this.tripChangeForm.value
        }).subscribe(res => {
            this.router.navigate(['/']);
            this.sb.open('Trip successfully changed');
        });
    }
}
