import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TripsService} from '../services/trips.service';
import { MatDialog } from '@angular/material/dialog';
import {BookTicketComponent} from '../book-ticket/book-ticket.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TicketsService} from '../services/tickets.service';
import * as moment from 'moment-timezone';
import {TransactionsService} from '../services/transactions.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PaymentSuccessDialogComponent} from '../payment-success-dialog/payment-success-dialog.component';
import {PaymentFailedDialogComponent} from '../payment-failed-dialog/payment-failed-dialog.component';
import {TripChangeComponent} from '../ticket/trip-change/trip-change.component';
import {MaskComponent} from './mask/mask.component';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.styl']
})
export class HomeComponent implements OnInit {
    searchForm: FormGroup;
    @ViewChild('formContainer', { static: true }) formContainer: ElementRef;
    ferrySchedule: any;
    ticket;
    selectedDay;
    noResults = false;
    loading = false;
    selectedDateControl = new FormControl(0);

    constructor(
        private ticketService: TicketsService,
        private transactionService: TransactionsService,
        private activatedRoute: ActivatedRoute,
        private tripService: TripsService,
        private router: Router,
        private dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.searchForm = new FormGroup({
            'ticketNo': new FormControl(null, Validators.required),
        });
        this.getTrips();
        this.maskRequired();
        this.activatedRoute.queryParamMap.subscribe(value => {
            if (value.has('OrderID')) {
                this.transactionService.receipt(value.get('OrderID')).subscribe(res => {
                    const success = this.dialog.open(PaymentSuccessDialogComponent, {data: res});
                    success.afterClosed().subscribe(_ => {
                        this.router.navigate([], {replaceUrl: true});
                    });
                });
            }
            if (value.has('message')) {
                const success = this.dialog.open(PaymentFailedDialogComponent, {data: {message: value.get('message')}});
                success.afterClosed().subscribe(res => {
                    this.router.navigate([], {replaceUrl: true});
                });
            }
        });
    }

    getTrips() {
        this.tripService.by_days().subscribe(res => {
            res.sort(function (a, b) {
                if (a.departure > b.departure) {
                    return 1;
                }
                if (a.departure < b.departure) {
                    return -1;
                }
                return 0;
            });
            const test = res.map((trip) => {
                return {
                    ...trip,
                    // departure: moment(trip.departure).utcOffset(new Date().getTimezoneOffset() / 60).toDate(),
                    date: moment.tz(trip.departure, moment.tz.guess()).format('DD MMM - ddd')
                };
            });
            const something = {};
            for (const item of test) {
                if (something[item.date]) {
                    something[item.date].push(item);
                } else {
                    something[item.date] = [item];
                }
            }
            // this.ferrySchedule = Object.keys(something).;
            for (const key of Object.keys(something)) {
                something[key] = something[key].sort(function (a, b) {
                    if (a.from > b.from) {
                        return 1;
                    }
                    if (a.from < b.from) {
                        return -1;
                    }
                    return 0;
                });
            }
            this.ferrySchedule = Object.entries(something);
            console.log(this.ferrySchedule);
            this.selectedDay = 0;
        });
    }

    changeSelected(schedule) {
        this.selectedDay = schedule;
    }

    openTicketDialog(trip): void {
        const dialogRef = this.dialog.open(BookTicketComponent, {width: '400px', data: trip});
    }

    maskRequired(): void {
        const dialogRef = this.dialog.open(MaskComponent, {width: '400px'});
    }

    searchTicket() {
        if (this.searchForm.value.ticketNo) {
            this.loading = true;
            this.ticketService.getTicket(this.searchForm.value.ticketNo).subscribe(res => {
                this.loading = false;
                this.noResults = !res;
                this.ticket = res;
            }, error1 => {
                console.log('asdkjajs');
                this.noResults = true;
                this.loading = false;
                this.ticket = null;
            });
        } else {
            this.loading = false;
            this.ticket = null;
            this.noResults = false;
        }
    }

    payNow(tickets) {
        // const ticket_ids = tickets.map(t => t._id);
        this.transactionService.post({'tickets': [tickets._id]}).subscribe(res => {
            window.location = res.url;
        });
    }

    changeTrip(ticket) {
        const res = this.dialog.open(TripChangeComponent, {data: ticket});
    }

    reCheck(ticketNo) {
        this.ticketService.reCheck(ticketNo).subscribe(res => {
            this.ticket = res;
        });
    }

    generateBankSubmissionForm(postUrl, data: any[]) {
        const f = document.createElement('form');
        f.setAttribute('method', 'post');
        f.setAttribute('action', postUrl);

        for (const item of data) {
            const i = document.createElement('input');
            i.setAttribute('type', 'hidden');
            i.setAttribute('name', Object.keys(item)[0]);
            i.setAttribute('value', Object.values<string>(item)[0]);
            f.appendChild(i);
        }

        this.formContainer.nativeElement.appendChild(f);
        this.formContainer.nativeElement.childNodes[0].submit();
    }

    compareWith(c1, c2): boolean {
        return c1 && c2 ? c1._id === c2 : c1 === c2;
    }
}
