import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-ticket',
    templateUrl: './ticket.component.html',
    styleUrls: ['./ticket.component.styl'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketComponent implements OnInit {
    @Input() ticket = null;

    constructor() {
    }

    ngOnInit() {
    }

}
