import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from '../header/header.component';
import {DateDecPipe} from '../date-dec.pipe';
import {DateTPipe} from '../date-t.pipe';
import {DateOnlyPipe} from '../date-only.pipe';
import {TimeOnlyPipe} from '../time-only.pipe';
import {FooterComponent} from '../footer/footer.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
    declarations: [
        HeaderComponent,
        DateDecPipe,
        DateTPipe,
        DateOnlyPipe,
        TimeOnlyPipe,
        FooterComponent,
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatDialogModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatCheckboxModule
    ],
    exports: [
        CommonModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatDialogModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatCheckboxModule,
        HeaderComponent,
        DateDecPipe,
        DateTPipe,
        DateOnlyPipe,
        TimeOnlyPipe,
        FooterComponent,
    ]
})
export class SharedModule {
}
