import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TransactionsService {
    apiBase = environment.apiBase;

    constructor(private http: HttpClient) {
    }

    list(filters = {}) {
        return this.http.get<any>(this.apiBase + '/transactions', { params: filters });
    }

    post(data) {
        return this.http.post<any>(this.apiBase + '/transactions', data);
    }

    get(id) {
        return this.http.get<any>(this.apiBase + '/transactions/' + id);
    }

    receipt(id) {
        return this.http.get<any>(this.apiBase + '/transactions/' + id + '/receipt');
    }

    put(id, data) {
        return this.http.put<any>(this.apiBase + '/transactions/' + id, data);
    }

    retry(id) {
        return this.http.get<any>(this.apiBase + '/transactions/' + id + '/retry', {});
    }
}
