import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

export const TicketStatus = {
    5: 'Expired',
    3: 'WaitingVerification',
    1: 'Confirmed',
    2: 'Pending',
    0: 'Waiting',
    6: 'No-Show',
    7: 'Changed Trip',
};

export const TicketPaymentStatus = {
    0: 'Pending',
    1: 'Paid',
    2: 'Credit',
    3: 'Refunded',
    4: 'Free',
};

@Injectable({
    providedIn: 'root'
})
export class TicketsService {
    apiBase = environment.apiBase;

    constructor(private http: HttpClient) {
    }

    list(filters = {}) {
        return this.http.get<any>(this.apiBase + '/tickets', { params: filters });
    }

    send_verification(data) {
        return this.http.post<any>(this.apiBase + '/tickets/send_verification', data);
    }

    reCheck(ticketNo) {
        return this.http.post<any>(this.apiBase + '/tickets/re-check/' + ticketNo, {});
    }

    sendTripChangeVerification(ticketNo) {
        return this.http.get<any>(`${this.apiBase}/tickets/${ticketNo}/trip_change_verification_code`, {});
    }

    tripChange(data) {
        return this.http.put<any>(`${this.apiBase}/tickets/${data._id}/change_trip`, data);
    }

    ticket_book(data) {
        return this.http.post<any>(this.apiBase + '/tickets', data);
    }

    getTicket(ticketNo) {
        return this.http.get<any>(this.apiBase + '/tickets/' + ticketNo);
    }

    post(data) {
        return this.http.post<any>(this.apiBase + '/tickets', data);
    }

    get(id) {
        return this.http.get<any>(this.apiBase + '/tickets/' + id);
    }

    put(id, data) {
        return this.http.put<any>(this.apiBase + '/tickets/' + id, data);
    }

    checkIn(element: any) {
        return this.http.put<any>(`${this.apiBase}/tickets/${element._id}/mark_checked_in`, {});
    }

    noShow(element: any) {
        return this.http.put<any>(`${this.apiBase}/tickets/${element._id}/mark_no_show`, {});
    }

    onspot_book(data: any) {
        return this.http.post<any>(this.apiBase + '/tickets/direct', data);
    }

    updatePayment(data: any) {
        return this.http.put<any>(`${this.apiBase}/tickets/${data._id}/internal_pay`, data);
    }

    refund(data: any) {
        return this.http.put<any>(`${this.apiBase}/tickets/${data._id}/refund`, data);
    }

    tripChangeInternal(data) {
        return this.http.put<any>(`${this.apiBase}/tickets/change_trip_internal`, data);

    }
}

