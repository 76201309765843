import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-payment-failed-dialog',
    templateUrl: './payment-failed-dialog.component.html',
    styleUrls: ['./payment-failed-dialog.component.styl']
})
export class PaymentFailedDialogComponent implements OnInit {

    constructor(private dialogRef: MatDialogRef<PaymentFailedDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }

    close() {
        this.dialogRef.close();
    }

}
