import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    apiBase = environment.apiBase;
    loading$ = new BehaviorSubject(false);

    constructor(private http: HttpClient) {
    }

    get isLoggedIn() {
        return !!(sessionStorage.getItem('token'));
    }

    get token() {
        return sessionStorage.getItem('token');
    }

    attemptLogin(data) {
        return this.http.post<any>(this.apiBase + '/users/authenticate', data);
    }

    setToken(token) {
        sessionStorage.setItem('token', token);
    }

    list() {
        return this.http.get<any>(`${this.apiBase}/users`);
    }

    get(id) {
        return this.http.get<any>(`${this.apiBase}/users/${id}`);
    }

    post(data) {
        return this.http.post<any>(`${this.apiBase}/users`, data);
    }

    put(id, data) {
        return this.http.put<any>(`${this.apiBase}/users/${id}`, data);
    }
}
