import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {TicketsService} from '../services/tickets.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {TransactionsService} from '../services/transactions.service';

@Component({
    selector: 'app-book-ticket',
    templateUrl: './book-ticket.component.html',
    styleUrls: ['./book-ticket.component.styl'],
    animations: [
        trigger('items', [
            transition(':enter', [
                style({transform: 'scale(0.5)', opacity: 0}),  // initial
                animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
                    style({transform: 'scale(1)', opacity: 1}))  // final
            ]),
            transition(':leave', [
                style({transform: 'scale(1)', opacity: 1, height: '*'}),
                animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
                    style({
                        transform: 'scale(0.5)', opacity: 0,
                        height: '0px', margin: '0px'
                    }))
            ])
        ])
    ]
})
export class BookTicketComponent implements OnInit {
    ticketForm: FormGroup;
    // verification;
    tickets;
    booked = false;
    showSpecialRequest = false;
    @ViewChild('formContainer', { static: true }) formContainer: ElementRef;

    constructor(
        private ticketService: TicketsService,
        private transactionService: TransactionsService,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
        console.log(this.data);
        this.ticketForm = new FormGroup({
            'names': new FormArray([
                this.personForm()
            ]),
            'phone': new FormControl({value: 960, disabled: false}, Validators.minLength(10)),
            'departureLocation': new FormControl(null),
            'specialRequest': new FormControl(null)
        });
    }

    addNewPerson() {
        (this.ticketForm.controls['names'] as FormArray).push(this.personForm());
    }

    removePerson(i: number) {
        if ((this.ticketForm.controls['names'] as FormArray).length > 1) {
            (this.ticketForm.controls['names'] as FormArray).removeAt(i);
        }
    }

    personForm() {
        return new FormGroup({
            nid: new FormControl({value: '', disabled: false}, Validators.required),
            name: new FormControl({value: '', disabled: false}, Validators.required)
        });
    }

    bookTicket(retry = null) {
        const formdata = this.ticketForm.value;
        formdata['tripId'] = this.data._id;
        if (this.tickets) {
            formdata['_ids'] = this.tickets;
        }
        this.ticketService.ticket_book(formdata).subscribe(res => {
            if (res.status) {
                this.booked = true;
                this.tickets = res.data;
            } else {
                this.tickets = res.data;
                this.ticketForm.addControl('verification_code', new FormControl('', Validators.required));
                (this.ticketForm.controls['names'] as FormArray)
                    .controls
                    .forEach(control => control.disable());
                this.ticketForm.controls['phone'].disable();
            }
        }, (error) => {
            this.snackBar.open(error.error.message, 'ok', {
                duration: 2000
            });
        });
    }

    payNow(tickets) {
        const ticket_ids = tickets.map(t => t._id);
        this.transactionService.post({'tickets': ticket_ids}).subscribe(res => {
            window.location = res.url;
        });
    }

    generateBankSubmissionForm(postUrl, data: any[]) {
        const f = document.createElement('form');
        f.setAttribute('method', 'post');
        f.setAttribute('action', postUrl);

        for (const item of data) {
            const i = document.createElement('input');
            i.setAttribute('type', 'hidden');
            i.setAttribute('name', Object.keys(item)[0]);
            i.setAttribute('value', Object.values<string>(item)[0]);
            f.appendChild(i);
        }

        this.formContainer.nativeElement.appendChild(f);
        this.formContainer.nativeElement.childNodes[0].submit();
    }

}
