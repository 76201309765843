import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TripsService {
    apiBase = environment.apiBase;

    constructor(private http: HttpClient) {
    }

    list(params) {
        const _params = new HttpParams({
            fromObject: {
                ...params
            }
        });
        return this.http.get<any>(`${this.apiBase}/trips?${_params.toString()}`);
    }

    by_days(filters = {}) {
        return this.http.get<any>(this.apiBase + '/trips/by_days', { params: filters });
    }

    get(id) {
        return this.http.get<any>(this.apiBase + '/trips/' + id);
    }

    post(data) {
        return this.http.post<any>(this.apiBase + '/trips', data);
    }

    from_waiting(data) {
        return this.http.post<any>(this.apiBase + '/trips/from_waiting_list', data);
    }

    put(id, data) {
        return this.http.put<any>(this.apiBase + '/trips/' + id, data);
    }

    remove(id) {
        return this.http.delete<any>(this.apiBase + '/trips/' + id);
    }
}
