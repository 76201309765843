import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateDec'
})
export class DateDecPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        return (moment(value).format('Do MMM  hh:mm')) + ' (' + moment(value).calendar().split(' at')[0] + ')';
    }

}
