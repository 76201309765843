import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {catchError, finalize} from 'rxjs/internal/operators';
import {Observable, throwError} from 'rxjs';
import {UserService} from './user.service';

@Injectable()
export class NxHttpService implements HttpInterceptor {

    constructor(private userService: UserService,
                private router: Router,
                private snackBar: MatSnackBar) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.userService.token}`
            }
        });
        this.userService.loading$.next(true);

        return next.handle(request)
            .pipe(
                catchError((err, caught) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            this.snackBar.open(this.getErrorMessage(err), null, {
                                duration: 3000
                            });
                            this.router.navigate(['/login']);
                        }
                        this.snackBar.open(this.getErrorMessage(err), null, {
                            duration: 6000
                        });
                        return throwError(err);
                    }
                }),
                finalize(() => this.userService.loading$.next(false))
            ) as any;
    }

    private getErrorMessage(err: HttpErrorResponse): string {
        return err.error.message || 'Unknown Server Error';
    }

}
