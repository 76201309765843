import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HomeComponent} from './home/home.component';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BookTicketComponent} from './book-ticket/book-ticket.component';
import {LoginComponent} from './login/login.component';
import {TicketComponent} from './ticket/ticket.component';
import {AuthGuard} from './guards/auth.guard';
import {NxHttpService} from './services/NxHttpService';
import {PaymentSuccessDialogComponent} from './payment-success-dialog/payment-success-dialog.component';
import {PaymentFailedDialogComponent} from './payment-failed-dialog/payment-failed-dialog.component';
import {TripChangeComponent} from './ticket/trip-change/trip-change.component';
import {SharedModule} from './shared/shared.module';
import { MaskComponent } from './home/mask/mask.component';

const appRoutes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: ':id/change-trip',
        component: TripChangeComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'admin',
        canActivate: [AuthGuard],
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
    },
];

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        BookTicketComponent,
        LoginComponent,
        TicketComponent,
        PaymentSuccessDialogComponent,
        PaymentFailedDialogComponent,
        TripChangeComponent,
        MaskComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        MatRippleModule,
        MatNativeDateModule,
        SharedModule
    ],
    entryComponents: [
        BookTicketComponent,
        PaymentSuccessDialogComponent,
        PaymentFailedDialogComponent,
        TripChangeComponent,
        MaskComponent
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NxHttpService,
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}

