import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.styl']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;

    constructor(private fb: FormBuilder,
                private userService: UserService,
                private sb: MatSnackBar,
                private router: Router) {
    }

    ngOnInit() {
        this.loginForm = this.fb.group({
            username: [null, Validators.required],
            password: [null, Validators.required]
        });

        this.userService.setToken('');
    }

    submit() {
        this.userService.attemptLogin(this.loginForm.value).subscribe(res => {
            this.userService.setToken(res.token);
            this.sb.open(`Welcome ${res.username}`, null, {duration: 5000});
            this.router.navigate(['admin/trips']);
        });
    }

}
