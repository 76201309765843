import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'timeOnly'
})
export class TimeOnlyPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        return moment(value).format('HH:mm');
    }

}
